<template>
    <div id="aboutus" class="container-aboutus px-0">
        <v-row no-gutters>
            <v-col align="center">
                <p class="text-title black--text"><span class="font-weight-bold">sobre</span>nós</p>
                <div class="limiter-content">
                    <br>
                    <p class="text-subtitle text-justify black--text">Somos uma equipe de especialistas apaixonados por
                        tecnologia,
                        dedicados a transformar ideias em soluções de software excepcionais. Nossa missão é impulsionar o
                        sucesso de
                        nossos clientes, fornecendo serviços de desenvolvimento de software de alto desempenho e inovação
                        tecnológica. Com anos de experiência, nossa equipe está comprometida em criar soluções
                        personalizadas
                        que atendam às necessidades exclusivas de cada cliente. Nosso foco é a excelência, a inovação e a
                        entrega no prazo. Junte-se a nós nesta jornada para o futuro digital.</p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
        };
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.pause();
            }
        },
    },
    methods: {
        ready(event) {
            this.player = event.target;
        },
        playing(event) {
            // The player is playing a video.
        },
        change() {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
            this.videoId = "another video id";
        },
        stop() {
            this.player.stopVideo();
        },
        pause() {
            this.player.pauseVideo();
        },
    },
};
</script>

<style lang="scss">
#hero {
    z-index: 0 !important;
}

.container-aboutus {
    padding: 60px;
    background-color: var(--v-secondary-base);
    position: relative;
    max-width: 100% !important;

    .row {
        height: 100%;
    }

    .text-subtitle {
        color: var(--v-secondary-base);
        text-align: center;
        font-family: 'Montserrat';
        text-transform: none;
        font-size: 20px;
    }
}

.text-title {
    color: var(--v-primary-base);
    text-align: center;
    letter-spacing: 0.3em;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 30px;
}
</style>