import Vue, { PluginObject } from 'vue'

const messages: Map<number, string> = new Map([
  [1000, 'E-mail não informado!'],
  [1012, 'Essa conta já existe!'],
  [1011, 'Usuário e/ou senha inválido(s)'],
  [1020, 'Conta não verificada, acesse seu e-mail e verifque sua conta!'],
  [1021, 'URL inválida!'],
  [1032, 'Mais de um credor encontrado com esse mesmo CPF/CNPJ!'],
  [1040, 'Usuário não encontrado!']
])

class CodesPlugin implements PluginObject<any> {
  install = (_Vue: typeof Vue, options?: any) => {
    _Vue.prototype.$codes = function(object: any) {
      var message = ''
      if (object && object.response && object.response.data.content) {
        const errorTmp = object.response.data.content.error;
        message =
          messages.get(errorTmp.code) || errorTmp.message || 'nenhuma informação disponível'
        if (errorTmp.code == 1002)
          message += `\n\n${errorTmp.message && errorTmp.message.substring(0, 500)}`
      }

      return message
    }
  };
  [key: string]: any
}

const plugin = new CodesPlugin()
Vue.use(plugin)
