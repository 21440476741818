import { render, staticRenderFns } from "./NavigationComponent.vue?vue&type=template&id=0bedd98d&scoped=true&"
import script from "./NavigationComponent.vue?vue&type=script&lang=js&"
export * from "./NavigationComponent.vue?vue&type=script&lang=js&"
import style0 from "./NavigationComponent.vue?vue&type=style&index=0&id=0bedd98d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bedd98d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAppBar,VAppBarNavIcon,VBtn,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VNavigationDrawer,VSpacer,VToolbarTitle})
