<template>
    <div id="home" class="container px-0">
        <v-row class="row" align="center">
            <v-col align="center">
                <v-row align="center">
                    <v-col align="center">
                        <p class="text-title white--text"><span class="font-weight-bold">mazzi</span>tech</p>
                        <p class="text-subtitle">potencializando negócios com códigos de excelência</p>
                        <v-btn color="secondary" class="text-underline" text @click="scrollToAnchor('contact')">
                            <span>Contate-nos</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row align="center">
                    <v-col align="center">
                        <div class="btn-arrow-down" @click="scrollToAnchor('aboutus')">
                            <v-icon color="secondary" size="50">mdi-arrow-down</v-icon>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            dialog: false,
        };
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.pause();
            }
        },
    },
    methods: {
        ready(event) {
            this.player = event.target;
        },
        playing(event) {
            // The player is playing a video.
        },
        change() {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
        },
        scrollToAnchor(anchor) {
            const section = document.getElementById(anchor);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        stop() {
            this.player.stopVideo();
        },
        pause() {
            this.player.pauseVideo();
        },
    },
};
</script>

<style lang="scss">
#hero {
    z-index: 0 !important;
}

.container {
    height: 720px;
    background-color: var(--v-primary-base);
    position: relative;
    max-width: 100% !important;

    .row {
        height: 100%;
    }
}

.text-title {
    color: var(--v-secondary-base);
    text-align: center;
    letter-spacing: 0.3em;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 30px;
}

.text-subtitle {
    color: var(--v-secondary-base);
    text-align: center;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 20px;
}

.btn-arrow-down {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 30px;
}
</style>