<template>
    <div id="partners" class="container-portfolio px-0">
        <v-row no-gutters>
            <v-col align="center">
                <p class="text-title black--text"><span class="font-weight-bold">Parceiros</span></p>
                <div class="limiter-content">
                    <br>
                    <p class="text-subtitle text-justify black--text">
                        Ao longo de nossa jornada, construímos relações sólidas e duradouras com empresas de diversos
                        setores, oferecendo soluções personalizadas e inovadoras que impulsionam o crescimento e a
                        eficiência de seus negócios.
                    </p>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <!-- <img src="@/assets/images/logo-b&j.png"/> -->
                <v-slide-group v-model="model" class="pa-4" active-class="success" show-arrows center-active>
                    <v-slide-item v-for="(client, index) in clients" :key="index" v-slot="{ active, toggle }">
                        <v-card height="200" width="400">
                            <v-row align="center">
                                <v-col align="center">
                                    <img :width="client.width || '300px'"
                                        :src="require(`@/assets/images/${client.image}`)" />
                                </v-col>
                            </v-row>
                            <!-- <v-row class="fill-height" align="center" justify="center">
                                <v-scale-transition>
                                </v-scale-transition>
                            </v-row> -->
                        </v-card>
                        <!-- <p>{{ client.name }}</p> -->
                        <!-- <v-img :src="'@/assets/images/' + client.image"></v-img> -->
                        <!-- <v-card :color="active ? undefined : 'grey lighten-1'" class="ma-4" height="200" width="100">
                            <v-row class="fill-height" align="center" justify="center">
                                <v-scale-transition>
                                    <v-icon v-if="active" color="white" size="48"
                                        v-text="'mdi-close-circle-outline'"></v-icon>
                                </v-scale-transition>
                            </v-row>
                        </v-card> -->

                    </v-slide-item>
                </v-slide-group>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            data: {
                name: "",
                email: "",
                subject: "",
                message: ""
            },
            model: null,
            clients: [
                {
                    name: 'Brizola e Japur',
                    image: 'logo-b&j.png'
                },
                {
                    name: 'Paoli Balbino e Barros',
                    image: 'logo-pbb.png'
                },
                {
                    name: '123 Milhas',
                    image: 'logo-123.png'
                },
                {
                    name: 'Grupo Carvalho',
                    image: 'logo-carvalho.png'
                },
                {
                    name: 'Lavepay',
                    image: 'logo-lavepay.png',
                    width: '150px'
                },
                {
                    name: 'Onix autolocadora',
                    image: 'logo-onix.png'
                },
                {
                    name: 'AFX',
                    image: 'logo-afx.png'
                },
                {
                    name: 'Axess',
                    image: 'logo-axess.png'
                },
            ]
        };
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.pause();
            }
        },
    },
    methods: {
        ready(event) {
            this.player = event.target;
        },
        playing(event) {
            // The player is playing a video.
        },
        change() {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
            this.videoId = "another video id";
        },
        stop() {
            this.player.stopVideo();
        },
        pause() {
            this.player.pauseVideo();
        },
    },
};
</script>

<style lang="scss">
#hero {
    z-index: 0 !important;
}

.container-portfolio {
    padding: 60px;
    background-color: var(--v-secondary-base);
    max-width: 100% !important;

    text-align: center !important;

    .row {
        height: 100%;
    }

    .text-subtitle {
        color: var(--v-secondary-base);
        text-align: center;
        font-family: 'Montserrat';
        text-transform: none;
        font-size: 20px;
    }
}

.text-title {
    color: var(--v-primary-base);
    text-align: center;
    letter-spacing: 0.3em;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 30px;
}
</style>