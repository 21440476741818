<template>
    <div>
        <!-- <v-navigation-drawer v-model="drawer" app temporary dark>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <img src="@/assets/images/logo-mazzitech.png" alt="Logo" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title">Calango</v-list-item-title>
                        <v-list-item-subtitle>WEB</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider />

            <v-list dense>
                <v-list-item v-for="([icon, text, link], i) in items" :key="i" link @click="$vuetify.goTo(link)">
                    <v-list-item-icon class="justify-center">
                        <v-icon>{{ icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="subtitile-1">{{
                            text
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer> -->

        <v-app-bar v-if="!isXs" app class="px-15" elevation="0" color="secondary" :class="{ expand: flat }">
            <v-toolbar-title>
                <v-img @click="scrollToAnchor('home')" src="@/assets/images/logo-mazzitech.png" contain max-width="200" />
            </v-toolbar-title>
            <v-spacer />
            <div>
                <v-btn v-for="(item, index) in menuItems" :key="index" text @click="scrollToAnchor(item.ref)">
                    <span class="mr-2">{{ item.text }}</span>
                </v-btn>
                <v-btn rounded outlined text @click="scrollToAnchor('contact')">
                    <span>Contate-nos</span>
                </v-btn>
            </div>
        </v-app-bar>
        <v-app-bar v-else app class="px-4" elevation="0" color="secondary" :class="{ expand: flat }">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isXs" />
            <v-toolbar-title>
                <v-img src="@/assets/images/logo-mazzitech.png" contain max-width="200" />
            </v-toolbar-title>
            <v-spacer />
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary color="primary">
            <v-list nav dense>
                <v-list-item-group>
                    <v-list-item v-for="(item, index) in menuItems" :key="index" link color="white">
                        <v-list-item-content>
                            <v-list-item-title><span class="white--text">{{ item.text }}</span></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<style scoped>
.v-toolbar {
    transition: 0.6s;
}

.expand {
    height: 80px !important;
    padding-top: 10px;
}
</style>

<script>
export default {
    data: () => ({
        drawer: null,
        isXs: false,
        menuItems: [
            { icon: "mdi-home-outline", text: "Início", ref: "home" },
            { icon: "mdi-information-outline", text: "Sobre nós", ref: "aboutus" },
            { icon: "mdi-download-box-outline", text: "Nossos serviços", ref: "services" },
            { icon: "mdi-currency-usd", text: "Parceiros", ref: "partners" },
            // { icon: "mdi-email-outline", text: "Contate-nos", ref: "#contact" },
        ],
    }),
    props: {
        color: String,
        flat: Boolean,
    },
    methods: {
        onResize() {
            this.isXs = window.innerWidth < 850;
        },
        scrollToAnchor(anchor) {
            const section = document.getElementById(anchor);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },

    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
};
</script>