<template>
    <div id="services" class="container-ourservices px-0">
        <v-row>
            <v-col align="center">
                <p class="limiter-content text-title white--text"><span class="font-weight-bold">nossos</span> serviços</p>
                <div class="limiter-content py-10">
                    <v-row>
                        <v-col sm="4" xs="12" v-for="(item, index) in items" :key="index">
                            <v-card class="ourservices-card" elevation="0">
                                <v-card-text>
                                    <v-avatar color="secondary" size="70">
                                        <v-icon size="40" color="primary">{{ item.icon }}</v-icon>
                                    </v-avatar>
                                </v-card-text>
                                <v-card-text>
                                    <span class="ourservices-card-title">{{ item.title }}</span>
                                </v-card-text>
                                <v-card-text>
                                    <span class="ourservices-card-description">
                                        {{ item.description }}
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            items: [
                {
                    icon: "mdi-file-table-box-multiple-outline",
                    title: "Desenvolvimento Customizado",
                    description: `Transformamos suas ideias em soluções digitais sob medida, 
                        alinhadas às suas necessidades específicas. 
                        Desde pequenos aplicativos até sistemas empresariais complexos, 
                        estamos aqui para desenvolver software que atenda aos seus objetivos.`
                },
                {
                    icon: "mdi-cellphone-text",
                    title: "Aplicações Móveis",
                    description: `Desenvolvemos aplicativos móveis inovadores para iOS e Android. Desde o design até a
                                implementação, garantimos que sua aplicação atenda aos padrões mais recentes da
                                indústria, proporcionando uma experiência envolvente aos usuários.`
                },
                {
                    icon: "mdi-monitor",
                    title: "Desenvolvimento Web",
                    description: `Criamos websites modernos, responsivos e intuitivos. 
                        Nossa abordagem focada no usuário garante uma experiência online excepcional para seus clientes. 
                        Oferecemos desde websites informativos até plataformas de comércio eletrônico robustas.`
                },
                {
                    icon: "mdi-source-branch",
                    title: "Integração de Sistemas",
                    description: `Conectamos seus sistemas existentes para otimizar processos e melhorar a eficiência operacional. 
                        A integração perfeita entre diferentes plataformas e aplicativos é a 
                        chave para um ambiente de negócios moderno e eficiente.`
                },
                {
                    icon: "mdi-fast-forward-outline",
                    title: "Desenvolvimento Ágil",
                    description: `Adotamos práticas de desenvolvimento ágil para garantir entregas rápidas e iterativas.
                                Colaboramos de perto com nossos clientes para garantir que suas expectativas sejam
                                superadas em cada estágio do desenvolvimento.`
                },
                {
                    icon: "mdi-forum-outline",
                    title: "Consultoria em TI",
                    description: `Oferecemos serviços de consultoria em tecnologia da informação para ajudar sua empresa a tomar decisões informadas. 
                        Desde avaliações de segurança até estratégias de migração para a nuvem, 
                        estamos comprometidos em impulsionar o sucesso de sua empresa.`
                }
            ]
        };
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.pause();
            }
        },
    },
    methods: {
        ready(event) {
            this.player = event.target;
        },
        playing(event) {
            // The player is playing a video.
        },
        change() {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
            this.videoId = "another video id";
        },
        stop() {
            this.player.stopVideo();
        },
        pause() {
            this.player.pauseVideo();
        },
    },
};
</script>

<style lang="scss">
#hero {
    z-index: 0 !important;
}

.ourservices-card {
    background-color: var(--v-primary-base) !important;
}

.container-ourservices {
    padding-top: 60px;
    background-color: var(--v-primary-base);
    position: relative;
    max-width: 100% !important;

    .row {
        height: 100%;
    }

    .text-subtitle {
        color: var(--v-secondary-base);
        text-align: center;
        font-family: 'Montserrat';
        text-transform: none;
        font-size: 20px;
    }
}

.ourservices-card-icon-container {
    // background-color: var(--v-secondary-base);
    width: 80px;
    border-radius: 100px;
}

.ourservices-card-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--v-secondary-base);
}

.ourservices-card-description {
    font-size: 14px;
    font-weight: 500;
    color: var(--v-secondary-base);
    text-align: justify !important;
}

.text-title {
    color: var(--v-primary-base);
    text-align: center;
    letter-spacing: 0.3em;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 30px;
}
</style>