import Vue from "vue";
import VueRouter from "vue-router";

//the route guard
const guard = function(to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
  );
  if (!token && to.path !== "/login" && !publicPath) {
    next({
      path: "/home",
    });
  } else {
    next();
  }
};

Vue.use(VueRouter);

const routes = [
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
