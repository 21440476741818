<template>
    <div id="contact" class="container-contact px-0">
        <v-row no-gutters>
            <v-col align="center">
                <p class="text-title white--text"><span class="font-weight-bold">Contate-</span>nos</p>
                <div class="limiter-content">
                    <br>
                    <p class="text-subtitle text-justify white--text">
                        Estamos ansiosos para discutir como podemos ajudar a impulsionar a inovação em sua empresa.
                        Entre em
                        contato para uma consulta personalizada e descubra como nossos serviços de desenvolvimento de
                        software podem atender às suas necessidades exclusivas.
                    </p>

                    <!-- <v-row>
                        <v-col>
                            <v-text-field class="contact-form-field" dense label="Nome completo" v-model="dataset.data.name"
                                required :rules="[$rules.required]" outlined hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field dense label="E-mail" v-model="dataset.data.email" required
                                :rules="[$rules.required]" outlined hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row> -->

                    <v-row class="mt-10">
                        <v-col align="left">
                            <v-card class="contact-card" elevation="0">
                                <v-card-text>
                                    <v-avatar color="secondary" size="50">
                                        <v-icon size="30" color="primary">mdi-email</v-icon>
                                    </v-avatar>
                                    <span class="ml-4 contact-card-title"><a class="mail-link"
                                            href="mailto:contato@mazzitech.com.br">
                                            contato@mazzitech.com.br</a></span>
                                </v-card-text>
                            </v-card>
                            <v-card class="contact-card" elevation="0">
                                <v-card-text>
                                    <v-avatar color="secondary" size="50">
                                        <v-icon size="30" color="primary">mdi-map-marker-outline</v-icon>
                                    </v-avatar>
                                    <span class="ml-4 contact-card-title"> Av. Assis Brasil, 6186 - São Sebastião, Porto
                                        Alegre - RS</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            dataset: {
                data: {}
            }
        };
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.pause();
            }
        },
    },
    methods: {
        ready(event) {
            this.player = event.target;
        },
        playing(event) {
            // The player is playing a video.
        },
        change() {
            // when you change the value, the player will also change.
            // If you would like to change `playerVars`, please change it before you change `videoId`.
            // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
            // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
            this.videoId = "another video id";
        },
        stop() {
            this.player.stopVideo();
        },
        pause() {
            this.player.pauseVideo();
        },
    },
};
</script>

<style lang="scss">
#hero {
    z-index: 0 !important;
}

.container-contact {
    padding: 60px;
    background-color: var(--v-primary-base);
    max-width: 100% !important;

    text-align: center !important;

    .row {
        height: 100%;
    }

    .text-subtitle {
        color: var(--v-secondary-base);
        text-align: center;
        font-family: 'Montserrat';
        text-transform: none;
        font-size: 20px;
    }
}

.text-title {
    color: var(--v-primary-base);
    text-align: center;
    letter-spacing: 0.3em;
    font-family: 'Montserrat';
    text-transform: uppercase;
    font-size: 30px;
}

.contact-card {
    background-color: var(--v-primary-base) !important;
}

.contact-card-icon-container {
    // background-color: var(--v-secondary-base);
    width: 80px;
    border-radius: 100px;
}

.contact-card-title {
    font-size: 14px;
    font-weight: 700;
    color: var(--v-secondary-base);
}

.contact-card-description {
    font-size: 14px;
    font-weight: 500;
    color: var(--v-secondary-base);
}

.mail-link {
    text-transform: none !important;
    font-style: normal;
    color: white !important;
    text-decoration: none;
}

.contact-form-field {
    // background-color: var(--v-secondary-base);
}
</style>