














import Vue from "vue";

import HomeComponent from "@/components/HomeComponent.vue";
import AboutUsComponent from "@/components/AboutUsComponent.vue";
import OurServicesComponent from "@/components/OurServicesComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import NavigationComponent from "@/components/NavigationComponent.vue";
import PortfolioComponent from "@/components/PortfolioComponent.vue";

//import Home from "./views/Home.vue";

export default Vue.extend({
    name: "App",

    components: {
        'navigation-component': NavigationComponent,
        'home-component': HomeComponent,
        'aboutus-component': AboutUsComponent,
        'ourservices-component': OurServicesComponent,
        'contact-component': ContactComponent,
        'portfolio-component': PortfolioComponent
    },

    data: () => ({
        color: "",
        flat: null,
    }),

    methods: {
    },

    created: function () {
        //$root.user = parse(localStorage.getItem('user'));
    }
});
